<template>
  <b-container>
    <b-row>
      <b-col>
        <span v-for="(g, gi) in links" :key="gi">
          <div class="pt-3"><b>{{ g.name }}</b></div>
          <div class="small text-muted pb-2">{{ g.desc }}</div>
          <span v-for="(i,ii) in g.items" :key="ii">
            <div class="d-inline-block small" style="width: 180px !important;">
              <a :href="i.url" target="_blank">{{ i.name }}</a>&nbsp;
              <span v-for="(e,ei) in i.extra" :key="ei">
                [<a :href="e.url" target="_blank">{{ e.name }}</a>]&nbsp;
              </span>
            </div>
            <div class="d-inline-block small text-muted">{{ i.desc }}</div>
            <div class="clearfix"></div>
          </span>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data: function () {
    return {
      links: []
    }
  },
  created() {
    let url = "/api/data/links.json?" + new Date().getTime();
    const promises = [url].map(url => fetch(url).then(r => r.json()));
    return Promise.all(promises).then(r => this.links = r[0]);
  }
}
</script>
